import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import BackgroundImg from 'gatsby-background-image'
import { useIntl } from 'gatsby-plugin-react-intl'
import parse from 'html-react-parser'

// Component
import FloatSelo from '../components/FloatingSelo/selo'

// CSS, SCSS
import './styles/lwoma.scss'

// Icons
import handFlower from '../images/sustentabilidade/icons/Sustentabilidade_1.svg'
import logo from '../images/lwoma/Lwoma.svg'
import Pagina from '../components/Pagina'

const Paralax = ({ paralax, paralaxMobile }) => {
  const image = getImage(paralax)
  const bgImage = convertToBgImage(image)
  const imageMobile = getImage(paralaxMobile)
  const bgImageMobile = convertToBgImage(imageMobile)
  const intl = useIntl()

  return (
    <>
      <div>
        <BackgroundImg
          {...bgImage}
          Tag="div"
          className="paralaxImage d-lg-block d-none"
        >
          <div className="container h-100">
            <div className="d-flex flex-column justify-content-center align-items-center h-100">
              <img className="logoLwoma" src={logo}/>
              <p className="text-white text-center">{parse(intl.formatMessage({ id: 'paginas.lwoma.paralax' }))}</p>
            </div>
          </div>
        </BackgroundImg>
        <BackgroundImg
          {...bgImageMobile}
          Tag="div"
          className="paralaxImage d-lg-none d-block"
        >
          <div className="container h-100">
            <div className="d-flex flex-column justify-content-center align-items-center h-100">
              <img className="logoLwoma" src={logo}/>
              <p className="text-white text-center">{parse(intl.formatMessage({ id: 'paginas.lwoma.paralax' }))}</p>
            </div>
          </div>
        </BackgroundImg>
        <div className="gradient-blue-to-green"></div>
      </div>
    </>
  )
}

const Gota = ({ gota, Documento }) => {
  const intl = useIntl()

  return <>
    <div className="container">
      <div className="row px-4">
        <div className="col-lg-5 p-0 pb-5 py-lg-5 pt-4 mt-lg-4 order-2 order-lg-1">
          <p className="line-height-normal letter-space-normal mb-3">{parse(intl.formatMessage({ id: 'paginas.lwoma.gota.p1' }))}</p>
          <p className="line-height-normal letter-space-normal mb-3">{parse(intl.formatMessage({ id: 'paginas.lwoma.gota.p2' }))}</p>
          <p className="line-height-normal letter-space-normal mb-3">{parse(intl.formatMessage({ id: 'paginas.lwoma.gota.p3' }))}</p>
          <a href={Documento} target="_blank" rel="noreferrer noopener"><button className="btn btn-primary btn-sustentabilidade">{parse(intl.formatMessage({ id: 'paginas.lwoma.gota.link' }))}</button></a>
        </div>
        <div className="col-lg-7 p-0 order-1 order-lg-2">
          <GatsbyImage image={gota} className="imageGota mt-4 mt-lg-0" />
        </div>
      </div>
    </div>
  </>
}

const Aplication = ({ planta, plantaMobile }) => {
  const intl = useIntl()

  const image = getImage(planta)
  const bgImage = convertToBgImage(image)
  const imageMobile = getImage(plantaMobile)
  const bgImageMobile = convertToBgImage(imageMobile)
  return (
    <>
      <div className="AplicationParalax d-none d-lg-block">
        <BackgroundImg
          {...bgImage}
          Tag="div"
          className="paralaxImageAplication"
        >
          <div className="section-green h-100 d-none d-lg-block">
            <div className="container h-100 w-100">
              <div className="row h-100 mw-100">
                <div className="col-6 d-flex align-items-center h-100 py-4">
                  <div className=" w-90">
                    <h2 className="text-white my-3 font-size-17 mb-4">{parse(intl.formatMessage({ id: 'paginas.lwoma.application.title' }))}</h2>
                    <div className="w-90">
                      <p className="text-white font-size-09 line-height-normal pr-4">{parse(intl.formatMessage({ id: 'paginas.lwoma.application.p1' }))}</p>
                      <ul className="text-white w-85 pl-3 ml-3">
                        <li className="mb-3"><strong>{parse(intl.formatMessage({ id: 'paginas.lwoma.application.l1' }))}</strong></li>
                        <li className="mb-3"><strong>{parse(intl.formatMessage({ id: 'paginas.lwoma.application.l2' }))}</strong></li>
                        <li className="mb-3"><strong>{parse(intl.formatMessage({ id: 'paginas.lwoma.application.l3' }))}</strong></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="offset-6"></div>
            </div>
          </div>
          <div className="h-100 mw-100 d-flex flex-column justify-content-end mx-0 d-block d-lg-none">
            <div className="d-flex align-items-center section-green py-5 px-4">
              <div className="">
                <h2 className="text-white my-3">{parse(intl.formatMessage({ id: 'paginas.lwoma.application.title' }))}</h2>
                <p className="text-white font-size-09 line-height-normal pr-5">{parse(intl.formatMessage({ id: 'paginas.lwoma.application.p1' }))}</p>
                <ul className="text-white w-80">
                  <li className="mb-3"><strong>{parse(intl.formatMessage({ id: 'paginas.lwoma.application.l1' }))}</strong></li>
                  <li className="mb-3"><strong>{parse(intl.formatMessage({ id: 'paginas.lwoma.application.l2' }))}</strong></li>
                  <li className="mb-3"><strong>{parse(intl.formatMessage({ id: 'paginas.lwoma.application.l3' }))}</strong></li>
                </ul>
              </div>
            </div>
          </div>
        </BackgroundImg>
        <div className="gradient-blue-to-green"></div>
      </div>
      <div className="AplicationParalax d-block d-lg-none">
        <BackgroundImg
          {...bgImageMobile}
          Tag="div"
          className="paralaxImageAplication"
        >
          <div className="section-green h-100 d-none d-lg-block">
            <div className="container h-100 w-100">
              <div className="row h-100 mw-100">
                <div className="col-6 d-flex align-items-center h-100 py-4">
                  <div className=" w-90">
                    <h2 className="text-white my-3 font-size-17 mb-4">{parse(intl.formatMessage({ id: 'paginas.lwoma.application.title' }))}</h2>
                    <div className="w-90">
                      <p className="text-white font-size-09 line-height-normal pr-4">{parse(intl.formatMessage({ id: 'paginas.lwoma.application.p1' }))}</p>
                      <ul className="text-white w-85 pl-3 ml-3">
                        <li className="mb-3"><strong>{parse(intl.formatMessage({ id: 'paginas.lwoma.application.l1' }))}</strong></li>
                        <li className="mb-3"><strong>{parse(intl.formatMessage({ id: 'paginas.lwoma.application.l2' }))}</strong></li>
                        <li className="mb-3"><strong>{parse(intl.formatMessage({ id: 'paginas.lwoma.application.l3' }))}</strong></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="offset-6"></div>
            </div>
          </div>
          <div className="h-100 mw-100 d-flex flex-column justify-content-end mx-0 d-block d-lg-none">
            <div className="d-flex align-items-center section-green py-5 px-4">
              <div className="">
                <h2 className="text-white my-3">{parse(intl.formatMessage({ id: 'paginas.lwoma.application.title' }))}</h2>
                <p className="text-white font-size-09 line-height-normal pr-5">{parse(intl.formatMessage({ id: 'paginas.lwoma.application.p1' }))}</p>
                <ul className="text-white w-80">
                  <li className="mb-3"><strong>{parse(intl.formatMessage({ id: 'paginas.lwoma.application.l1' }))}</strong></li>
                  <li className="mb-3"><strong>{parse(intl.formatMessage({ id: 'paginas.lwoma.application.l2' }))}</strong></li>
                  <li className="mb-3"><strong>{parse(intl.formatMessage({ id: 'paginas.lwoma.application.l3' }))}</strong></li>
                </ul>
              </div>
            </div>
          </div>
        </BackgroundImg>
        <div className="gradient-blue-to-green"></div>
      </div>
    </>
  )
}

const Sustentabilidade = () => {
  const intl = useIntl()

  return (
    <>
      <div className="container">
        <div className="row pt-lg-5 justify-content-center px-4">
          <div className="col-lg-1 p-0">
            <img className="rounded-circle bg-lwart-green w-80 p-2 mt-5 mb-3 hand-flower-size" src={handFlower} draggable={false} alt="Icon" />
          </div>
          <div className="col-lg-8 mt-lg-4 px-lg-3 mb-5 p-0">
            <h3 className="text-lwart-green mb-3 font-size-1125">{parse(intl.formatMessage({ id: 'paginas.lwoma.sustentabilidade.title1' }))}</h3>
            <h2 className="text-lwart-blue mb-3 font-size-2 text-left">{parse(intl.formatMessage({ id: 'paginas.lwoma.sustentabilidade.title2' }))}</h2>
            <p className="mb-4">{parse(intl.formatMessage({ id: 'paginas.lwoma.sustentabilidade.p1' }))}</p>
            <p className="mb-0"><strong>{parse(intl.formatMessage({ id: 'paginas.lwoma.sustentabilidade.p2' }))}</strong></p>
          </div>
        </div>
      </div>
    </>
  )
}

const lwoma = ({ data }) => {
  console.log(data)
  return <>
    <Pagina pagina={data.pagina} />
    <FloatSelo />
    <Paralax paralax={data.paralax.childImageSharp.gatsbyImageData} paralaxMobile={data.paralaxMobile.childImageSharp.gatsbyImageData} />
    <Gota gota={data.gota.childImageSharp.gatsbyImageData} Documento={data.arquivo && data.arquivo.arquivo.file.url} />
    <Aplication planta={data.plantaparalax.childImageSharp.gatsbyImageData} plantaMobile={data.plantaparalaxMobile.childImageSharp.gatsbyImageData} />
    <Sustentabilidade />
  </>
}

export default lwoma

export const query = graphql`{
  arquivo: contentfulDocumentos(categoria: {eq: "lwoma"}) {
    arquivo {
      file {
        url
      }
    }
  }
  pagina: contentfulPagina(path: {eq: "/lwoma/"}) {
    path
    metaTitle
    metaDescription
    pageTitle
    tags
    ogImage {
      localFile {
        publicURL
      }
    }
  }
  paralax: file(relativePath: {eq: "lwoma/shutterstock_797105296.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  paralaxMobile: file(relativePath: {eq: "lwoma/lwoma.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 400, height: 600, layout: CONSTRAINED)
    }
  }
  plantaparalax: file(relativePath: {eq: "lwoma/shutterstock_1343726615a.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  plantaparalaxMobile: file(relativePath: {eq: "lwoma/lwomaPlantaMobile.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  gota: file(relativePath: {eq: "lwoma/gota1-copy.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  woman: file(relativePath: {eq: "gente/menina.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  conduta: file(relativePath: {eq: "gente/Conduta2.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
}
`
