import React from 'react'
import './selo.scss'
import selo from '../../images/icon/selo.svg'
import seloEnglish from '../../images/icon/co2-english.svg'
import seloEspanhol from '../../images/icon/co2-espanhol.png'
import { useIntl } from 'gatsby-plugin-react-intl'

export default function FloatingIcon (props) {
  const intl = useIntl()

  return (
    <>
      <div className='floatSelo'>
        {intl.locale === 'pt'
          ? <img src={selo} />
          : intl.locale === 'en'
            ? <img src={seloEnglish} width='115px' />
            : <img src={seloEspanhol} width='115px' />
        }
      </div>
    </>
  )
}
